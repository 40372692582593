import React from 'react';
import styled from 'styled-components';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import { Container, Content as StyledContent } from '../../components/home/homeStyledComponents';
import Retail from '../../components/home/Retail';

import Newsletter from '../../components/home/SustainableFashion/Newsletter';

const Content = styled(StyledContent)`
  z-index: 1;
  margin-top: -300px;
  padding-top: 300px;
`;

const RetailPage = () => (
  <Layout routeSlug="Retail">
    <Container>
      <Content>
        <MenuBar />
        <Retail />
        <Newsletter />
        <Footer />
      </Content>
    </Container>
  </Layout>
);

export default RetailPage;
