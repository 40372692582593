import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import background from '../../assets/retail/background.jpg';
import monnier from '../../assets/retail/monnier.jpg';
import lorette from '../../assets/retail/lorette.jpg';
import printemps from '../../assets/retail/printemps.jpg';
import monnierMobile from '../../assets/retail/monnier.mobile.jpg';
import loretteMobile from '../../assets/retail/lorette.mobile.jpg';
import printempsMobile from '../../assets/retail/printemps.mobile.jpg';
import withAppContext from '../../withAppContext';
import {
  colors, mobileThresholdPixels, Title1 as StyledTitle1, contentWidth, margins,
} from './v3/styledComponents';

const Container = styled.div`
  max-width: ${contentWidth}px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 100vw;
    margin: 0px 18px;
  }
`;

const IntroContainer = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 846px;
  padding: 0px 22px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
  }
`;

const Title1 = styled(StyledTitle1)`
  margin-bottom: 10px;
`;

const SubTitle = styled.h2`
  font-family: Libre Baskerville;
  font-style: italic;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  color: ${colors.green};
  margin: 0px 0px 30px;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const Paragraph = styled.div`
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: ${colors.navy};
  max-width: 630px;
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
  }
`;

const LineHorizontal = styled.div`
  background-color: ${colors.navy};
  height: 2px;
  width: 57px;
  margin: 0px auto 50px;
  ${({ forMobileOnly }) => forMobileOnly ? 'display: none;' : ''}

  @media (max-width: ${mobileThresholdPixels}) {
    display: block;
    margin: 33px auto 39px;
    width: 93px;
  }
`;

const ShopsContainer = styled.div`
  margin: 0px 50px 120px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px 60px;
  }
`;

const ShopRow = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: ${({ isEven }) => isEven ? 'row' : 'row-reverse'};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const ShopBlock = styled.div`
  text-align: left;
  ${({ isEven }) => isEven ? 'margin-left: 100px;' : 'margin-right: 60px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    text-align: center;
  }
`;

const ShopTitleContainer = styled.div`
  position: relative;
`;

const ShopTitle = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
  letter-spacing: 1px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 32px;
  }

  ${({ isEven }) => isEven ? `
    &:before {
      font-weight: lighter;
      font-size: 26px;
      color: ${colors.white};
      z-index: 0;
      content: '——';
      letter-spacing: -10px;
      top: -15px;
      margin-right: ${margins.s};
      left: -60px;
      position: absolute;
      border-bottom: ${colors.navy} 1px solid;
      @media (max-width: ${mobileThresholdPixels}) {
        content: '';
      }
    }
  ` : `
    &:after {
      font-weight: lighter;
      font-size: 26px;
      color: ${colors.white};
      z-index: 0;
      content: '——';
      letter-spacing: -10px;
      top: -15px;
      margin-left: ${margins.s};
      right: 0px;
      position: relative;
      border-bottom: ${colors.navy} 1px solid;
      @media (max-width: ${mobileThresholdPixels}) {
        content: '';
      }
    }
  `}
`;

const ShopAddress = styled.p`
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
  }
`;

const ShopOpeningHours = styled.p`
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 75px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 30px;
  }
`;

const ShopDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
  }
`;

const MapLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${colors.navy};
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    display: block;
  }
`;

const shopsData = [
  {
    name: 'Henry Monnier',
    description: `Au coeur du quartier Saint-Georges, l’adresse Henry Monnier est la boutique historique de Tilli.
    Véritable lieu de rencontre, c’est en vous y voyant de plus en plus nombreux que nous avons eu l’envie d’aller plus
    loin et d’être toujours au plus près de vous. Nos Tillistes y sont présents pour toute retouche, réparation et transformation de vos vêtements.`,
    address: '1 rue Henry Monnier, 75009 Paris',
    openingHours: 'Du mardi au samedi, de 11:00 à 20:00',
    photo: monnier,
    photoMobile: monnierMobile,
    altTag: 'Boutique de retouche couture upcycling à Paris',
    // eslint-disable-next-line max-len
    mapUrl: 'https://www.google.com/maps/place/Tilli+-+Couturier+Retouches+-+Henry+Monnier/@48.8793232,2.3347152,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66f92b370babb:0x75a1d4060d6b9485!8m2!3d48.8793197!4d2.3369082',
  },
  {
    name: 'Notre-Dame de Lorette',
    description: `Situé à quelques pas de la boutique Henry Monnier. L’adresse Notre-Dame de Lorette devient le lieu de
    l’upcycling à Paris. Sur 100m² et 2 étages, vous pourrez vous laisser guider par nos artisans pour prendre soin de votre
    dressing, de vos intérieurs et de vos éléments de décoration. L’occasion de plonger dans notre univers et de vous laisser
    surprendre par un sous-sol rempli de tissus à la découpe pour toutes vos créations.`,
    address: '10 rue Notre-Dame de Lorette, 75009 Paris',
    openingHours: 'Du mardi au samedi, de 11:00 à 20:00',
    photo: lorette,
    photoMobile: loretteMobile,
    altTag: 'Retoucherie de vêtement et rideaux à Paris 9',
    // eslint-disable-next-line max-len
    mapUrl: 'https://www.google.com/maps/place/Tilli+-+Couturier+Retouches+-+Notre-Dame+de+Lorette/@48.8774913,2.3364921,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66f36c3dd143b:0x6dc27143c8a7c837!8m2!3d48.8774847!4d2.338733',
  },
  {
    name: 'Printemps Haussmann',
    description: `Perché au 7ème Ciel du Printemps Haussmann, le plus grand lieu de la mode circulaire en France, notre corner
    Tilli sera pour vous l’occasion de découvrir le savoir-faire de nos Tillistes. Orné de milliers de pièces de mercerie vintage,
    de tissus et autres gadgets, vous pourrez vous inspirer de nos créations sur costumes pour personnaliser et donner une seconde
    vie à vos pièces préférées.`,
    address: '7ème étage mode Femme, Printemps Haussmann, 64 bd Haussmann, 75009 Paris',
    openingHours: 'Du lundi au samedi de 10:00 à 20:00, le dimanche de 11:00 à 20:00',
    photo: printemps,
    photoMobile: printempsMobile,
    altTag: 'Réparation de vêtements en boutique de retouche',
    // eslint-disable-next-line max-len
    mapUrl: 'https://www.google.com/maps/place/Tilli+-+Couturier+Retouches+-+Printemps+Haussmann/@48.8734334,2.3253031,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66f8b6d19c14d:0x485af70789c4c138!8m2!3d48.8734297!4d2.3274914',
  },
];

const renderIntroduction = () => (
  <IntroContainer>
    <Title1>Nos lieux Tilli</Title1>
    <SubTitle>Bienvenue chez votre couturier</SubTitle>
    <LineHorizontal />
    <ImageContainer>
      <Img src={background} alt="retoucherie Paris 9" />
    </ImageContainer>
    <Paragraph>
      Tilli vous accueille dans nos boutiques Parisiennes, des adresses qui réinventent l’expérience de la couture
      et de la retouche traditionnelle en proposant des services sur-mesure autour de la seconde vie des vêtements
      et de l’univers maison.
      <br />
      <br />
      Les boutiques TILLI deviennent un lieu d’échange, d’inspiration et de créativité où le client peut demander
      conseil à un artisan expert, faire retoucher le bas de son tailleur entre deux rendez-vous, transformer une pièce
      vintage dénichée en brocante ou encore co-créer le parfait accessoire pour déambuler dans les rues animées de la Capitale.
      <br />
      <br />
      Ces retoucheries, d’un nouveau genre, sont également des terrains de jeu pour les marques ayant l’envie de
      s’engager pour une mode toujours plus durable.
    </Paragraph>
  </IntroContainer>
);

const renderShopMapLink = (link) => (
  <MapLink href={link} target="_blank" rel="noopener noreferrer">Voir sur la map</MapLink>
);

const renderShops = (isMobile) => (
  <ShopsContainer>
    {shopsData.map((shop, index) => (
      <ShopRow key={shop.name} isEven={(index % 2) === 0}>
        <LineHorizontal onMobileOnly />
        <Img src={isMobile ? shop.photoMobile : shop.photo} alt={shop.altTag} />
        <ShopBlock isEven={(index % 2) === 0}>
          <ShopTitleContainer>
            <ShopTitle isEven={(index % 2) === 0}>{shop.name}</ShopTitle>
          </ShopTitleContainer>
          <ShopDescription>{shop.description}</ShopDescription>
          <br />
          <br />
          <ShopAddress>{shop.address}</ShopAddress>
          <ShopOpeningHours>{shop.openingHours}</ShopOpeningHours>
          {shop.mapUrl && renderShopMapLink(shop.mapUrl)}
        </ShopBlock>
      </ShopRow>
    ))}
  </ShopsContainer>
);

const Retail = ({ context: { isMobile } }) => (
  <Container>
    {renderIntroduction()}
    {renderShops(isMobile)}
  </Container>
);

Retail.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

Retail.defaultProps = {
};

export default withAppContext(Retail);
