import React from 'react';
import styled from 'styled-components';

import callApi from '../../../services/api';
import { isEmailValid } from '../../../services/checkingFormat';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-top: 36px;
  padding: 61px 0px 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-top: 11px;
    padding: 34px 0px 76px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.16em;
  text-align: center;
  margin-bottom: 78px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    width: 214px;
    margin-bottom: 27px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputAndButtonWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ hasSubText }) => hasSubText ? 13 : 37}px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: ${({ hasSubText }) => hasSubText ? 10 : 32}px;
  }
`;

const Button = styled.button`
  border: 1.5px solid ${({ theme }) => theme.colors.navy};
  box-sizing: border-box;
  width: 183px;
  height: 54px;
  display: flex;
  color: ${({ theme }) => theme.colors.navy};
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin-left: 85px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-left: 25px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.navy};
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-left: 11px;
    width: 126px;
    max-width: 126px;
    height: 44px;
    font-size: 14px;
    line-height: 24px;
  }
`;

const Input = styled.input`
  border: 0px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 0.8px solid ${({ isValid }) => !isValid ? ({ theme }) => theme.colors.red : ({ theme }) => theme.colors.navy};
  box-sizing: border-box;
  width: 370px;
  height: 54px;
  padding: 0px 10px 0px 0px;
  color: ${({ theme }) => theme.colors.navy};
  font-size: 18px;
  letter-spacing: 0.01em;

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGrey2};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    flex: 1;
    width: unset;
    max-width: 204px;
    height: 44px;
  }
`;

const InfoText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  color: ${({ isErrorText }) => isErrorText ? ({ theme }) => theme.colors.red : ({ theme }) => theme.colors.navy};
  width: 100%;
  margin: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 22px;
  }
`;

class Newsletter extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isValid: true,
      hasSubscribed: false,
    };
  }

  setInputValue = (event) => {
    const { target } = event;

    this.setState({
      [target.name]: target.value,
      isValid: true,
    });
  }

  registerToNewsletter = () => {
    const { email } = this.state;

    if (!isEmailValid(email)) {
      this.setState({ isValid: false });
    } else {
      callApi('/public/registerToNewsletter', 'POST', { email }).then(() => this.setState({ email: '', hasSubscribed: true }));
    }
  }

  render() {
    const { email, hasSubscribed, isValid } = this.state;
    return (
      <Wrapper>
        <Title>Recevez toutes nos actus</Title>
        <FormWrapper>
          <InputAndButtonWrapper hasSubText={hasSubscribed || !isValid}>
            <Input
              type="email"
              placeholder="exemple@exemple.fr"
              value={email}
              name="email"
              onChange={this.setInputValue}
              isValid={isValid}
            />
            <Button onClick={this.registerToNewsletter}>S’inscrire</Button>
          </InputAndButtonWrapper>
          {hasSubscribed && <InfoText>Votre inscription a bien été prise en compte</InfoText>}
          {!isValid && <InfoText isErrorText>Adresse non valide</InfoText>}
        </FormWrapper>
      </Wrapper>
    );
  }
}

export default Newsletter;
